import React from 'react';
import { useParams } from 'react-router-dom';
import LandingPage from 'web/components/LandingPage';

const UseCases = () => {
  const { slug } = useParams();
  return <LandingPage documentType="landingPage" slug={slug} />;
};

export default UseCases;
